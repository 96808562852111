import React from "react"

import {
  H2,
  H3,
  buttonBckg,
  LandingButton,
  OpenDetails,
} from "../../globalStyles"
import Phases from "../../components/About/Phases"
import styled from "styled-components"
import useToggleState from "../../hooks/useToggleState"
function Service(props) {
  const {
    img,
    width,
    description,
    title,
    dark,
    index,
    button,
    detailsList,
    id,
  } = props
  // console.log(detailsList)
  const [open, openDetails] = useToggleState(false)

  let details = detailsList.map((x, i) => (
    <Phases
      title={x.title}
      open={open}
      key={i}
      bullets={x.details}
      dark={dark}
    />
  ))

  let images = (
    <ImgDiv className="col-10 mt-1 mb-1 col-md-6 my-auto  col-lg-6  ">
      <FloatImg dark={dark} src={img.file.url} alt={img.title} />
    </ImgDiv>
  )
  return (
    <Row
      ref={props.myRef}
      id={id}
      dark={dark}
      className="row justify-content-center mr-auto ml-auto text-center "
    >
      {width > 640 ? index % 2 === 0 && images : null}
      <ColMin
        dark={dark}
        text
        className="col-12 col-md-6 mt-5 col-lg-6 text-left "
      >
        <H2
          service
          dark={dark}
          className="mb-3"
          style={{
            color: dark ? buttonBckg.darkHover : "white",
          }}
        >
          {title}
        </H2>
        <H3 dark={dark}>{description}</H3>
        <LandingButton onClick={openDetails} open={open} dark={!dark}>
          {button} <span>⇢</span>
        </LandingButton>
      </ColMin>
      {width > 640 ? index % 2 === 1 && images : null}
      {/*  */}
      <OpenDetails className="row justify-content-around text-left" open={open}>
        {details}
      </OpenDetails>
      {width < 641 && images}
    </Row>
  )
}

export default Service

export const Row = styled.div`
  width: 95%;
  transition: all 400ms;
  border-radius: 15px;
  background: ${({ dark }) => (dark ? "rgba(255,255,255,.4)" : null)};
  opacity: 0;
  /* background: red; */
  max-height: auto;
  @media (max-width: 640px) {
    margin-top: 8%;
    padding: 0;
    margin-bottom: 8%;
  }

  @media (min-width: 641px) {
    margin-top: 8%;
    padding: 20px 0 20px 0;
    margin-bottom: 8%;
  }

  @media (min-width: 1024px) {
  }
`

export const ColMin = styled.div`
  height: auto;

  @media (max-width: 640px) {
    margin: 5% 0 0 0;

    ${({ top }) => top && `margin-bottom:5%; margin-top:10%;`}
  }

  @media (min-width: 641px) {
    ${({ top }) => top && `margin-top:10%;`}
    margin-bottom:25px;
    ${({ text, dark }) =>
      text && dark
        ? `  border-left: 2px solid ${buttonBckg.dark};
  border-right: 2px solid ${buttonBckg.dark};`
        : `  border-left: 2px solid ${buttonBckg.light};
  border-right: 2px solid ${buttonBckg.light};`}
  }

  @media (min-width: 1024px) {
    ${({ top }) => top && `margin-top:3%; margin-bottom:3%;`}
    ${({ top }) => !top && `margin-top:2%; `}
  }
`

const ImgDiv = styled.div`
  @media (max-width: 640px) {
    /* width: 80%;
    border-bottom: 2px solid ${buttonBckg.light}; */
  }
`

const FloatImg = styled.img`
  position: relative;
  display: block;
  z-index: 10;

  margin: auto;

  @media (max-width: 640px) {
    width: 90%;
    margin-top: 15% !important;
    margin-bottom: 23% !important;
    padding-bottom: 10%;
    border-bottom: 3px solid ${buttonBckg.light};
    ${({ dark }) =>
      dark
        ? `  border-bottom: 2px solid ${buttonBckg.dark};`
        : `  border-bottom: 2px solid ${buttonBckg.light};
`}
  }

  @media (min-width: 641px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 60%;
  }
`
