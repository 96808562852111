import React, { useContext, useEffect, useRef } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"

import Service from "./Service"
import { H1, H3, Row, buttonBckg, StarsSix } from "../../globalStyles"
import { FullContainerLocal, Planets, ColMin } from "./ServicesStyle"
// import { graphql, useStaticQuery } from "gatsby"
import Footer from "../HomeSuccessSection/CustomFooter"

import useWindowSize from "../../hooks/resizeHook"
import { scrollInNatur } from "../GSAP/gsap"
function Services({ data }) {
  const { isDarkMode } = useContext(ThemeContext)
  const [width] = useWindowSize()

  let main = data.allContentfulServicesMain.nodes[0]
  let { para, svg, title } = main

  let servicesArray = data.allContentfulServicesEach.nodes
  // console.log(servicesArray)
  let details = data.allContentfulServiceDetailsList.nodes

  const serviceRef = useRef(null)
  serviceRef.current = []

  const addToRefs = el => {
    if (el && !serviceRef.current.includes(el)) {
      serviceRef.current.push(el)
    }
  }

  useEffect(() => {
    if (serviceRef.current.length === 5) {
      scrollInNatur(serviceRef.current, 100, 0, 0.85)
    }
    // console.log()

    return () => {}
  }, [width])

  let services = servicesArray.map((x, i) => (
    <Service
      // myRef={i !== 0 ? addToRefs : null}
      myRef={addToRefs}
      title={x.title}
      id={x.serviceType}
      detailsList={details.filter(e => e.serviceType === x.serviceType)}
      img={x.svg[0]}
      index={i}
      key={i}
      dark={isDarkMode}
      width={width}
      description={x.para.para}
      button={x.buttonText}
    />
  ))
  return (
    <FullContainerLocal dark={isDarkMode} className="container-fluid">
      <Planets
        dark={isDarkMode}
        stripe
        src={svg[1].file.url}
        alt={svg[1].title}
      />
      <Planets purple src={svg[0].file.url} alt={svg[0].title} />
      {!isDarkMode && <StarsSix top />}
      <Row>
        <div className="row justify-content-center   mx-auto text-center align-items-center">
          <ColMin
            small
            top
            className="col-12 p-0 col-md-8 col-lg-7 text-center mx-auto"
          >
            <H1
              servicesPage
              className="mx-auto"
              // landing
              style={{
                color: isDarkMode ? buttonBckg.darkHover : "white",
              }}
            >
              {title}
            </H1>
            <H3
              className="mt-5 p-auto"
              style={{ color: isDarkMode ? "#505050" : "white" }}
            >
              {para.para}
            </H3>
          </ColMin>
        </div>
        {services}
        {!isDarkMode && <StarsSix bottom />}
        <Footer footer={"true"} dark={isDarkMode} />
      </Row>
    </FullContainerLocal>
  )
}

export default Services
