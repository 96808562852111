import React from "react"
import Layout from "../components/Layout"
import ServicesComponent from "../components/Services/Services"
import { graphql, useStaticQuery } from "gatsby"
import HelmetComponent from "../components/Helmet"
import useIsClient from "../hooks/rehydration"
// import Loader from "../components/Loader/Loader"
function Services() {
  const { isClient } = useIsClient()

  const data = useStaticQuery(graphql`
    {
      allContentfulSeoEach(
        sort: { fields: createdAt, order: ASC }
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            id
            title
            url
            description
            facebookImage {
              fixed {
                srcWebp
              }
            }
          }
        }
      }
      allContentfulServicesMain(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          title
          para {
            para
          }
          svg {
            file {
              url
            }
            title
          }
        }
      }
      allContentfulServicesEach(
        sort: { fields: createdAt }
        filter: { node_locale: { eq: "hu" } }
      ) {
        nodes {
          title
          para {
            para
          }
          serviceType
          buttonText
          svg {
            file {
              url
            }
            title
          }
        }
      }
      allContentfulServiceDetailsList(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          title
          serviceType
          details {
            raw
          }
        }
      }
    }
  `)
  let seo = data.allContentfulSeoEach.edges[2].node
  if (!isClient) return <HelmetComponent seo={seo} />
  return (
    <Layout>
      <HelmetComponent seo={seo} />
      <ServicesComponent data={data} />
    </Layout>
  )
}

export default Services
